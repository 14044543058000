import styled from "styled-components";
import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledOverTimeView = styled.div`
    padding-bottom: 4rem;
    .overtime {
        text-align: left;
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        &__form {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
                justify-content: space-between;
            }
        }
        &__filter {
            padding: 2rem 0;
            display: flex;
            overflow-y: auto;
            column-gap: 0.5rem;
            margin-bottom: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                grid-template-columns: auto auto auto auto;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                max-width: 80rem;
                margin-bottom: 0;
            }
            .active {
                border: 1px solid ${(p) => p.theme.colors.secondary} !important;
                .title {
                    color: ${(p) => p.theme.colors.secondary}!important;
                }
            }
            &-item {
                display: flex;
                padding: 0.5rem 1rem;
                border-radius: 1.6rem;
                border: 1px solid ${(p) => p.theme.colors.darkBlack};
                justify-content: center;
                cursor: pointer;
                min-width: 12rem;
                .title {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    padding-right: 1rem;
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    height: 20px;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &__search {
            display: flex;
            align-items: center;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 30rem;
            }
            .wrapperSelect {
                width: 100%;
            }
        }
        &__title {
            display: flex;
            justify-content: space-between;
            &-btn {
                display: flex;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    flex-grow: 1;
                    justify-content: flex-end;
                }
                align-items: flex-end;
                .MuiButton-root {
                    font-size: 1.6rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    background-color: ${(p) => p.theme.colors.darkBlack};
                    color: white;
                }
            }
        }
        &__listItem {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 90%;
            }
            .form {
                &__checkbox {
                    padding: 1.5rem 0;
                    .MuiFormGroup-root {
                        display: flex;
                        flex-direction: row;
                    }
                    .MuiFormControlLabel-label {
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    }
                    .MuiTypography-root {
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                        font-size: 1.2rem;
                        color: #d5d5d5;
                    }
                    .MuiSvgIcon-root {
                        width: 1.5em;
                        height: 1.5em;
                    }
                    .active {
                        .MuiTypography-root {
                            color: ${(p) => p.theme.colors.darkBlack} !important;
                        }
                    }
                }
                &__searchInput {
                    padding: 2rem 0;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                        width: 40%;
                    }
                }
            }
            .wrapperLoading {
                padding-top: 4rem;
            }
        }
    }
`;

export const StyledStickyOvertimeHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 1000; // Adjust z-index as needed to ensure it stays above other content
    background-color: white; // Match the background color to prevent overlap artifacts
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); // Optional: Add a subtle shadow for distinction
`;

export const StyledOTItem = styled.div`
    .item {
        width: 100%;
        padding: 1.6rem;
        margin: 1.5rem 0;
        border-bottom: 1px solid #d6d6d6;
        &__top {
            display: flex;
            justify-content: space-between;
        }
        &__userInfo {
            display: flex;
            flex: 2;
            &-title {
                padding-left: 1rem;
                .name {
                    font-size: 1.6rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    display: block;
                    display: -webkit-box;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        &__status {
            &-checked {
                padding: 0.4rem 1rem;
                background-color: #d6fddc;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #0aba3b;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-unChecked {
                padding: 0.4rem 1rem;
                background-color: #fdd6d6;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #ba0a0a;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-pending {
                padding: 0.4rem 1rem;
                background-color: #e9e9e9;
            }
        }
        &__title {
            flex: 2;
            .content {
                display: block;
                display: -webkit-box;
                max-width: 100%;
                height: 20px;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &__date {
            display: flex;
            justify-content: space-between;
            padding-top: 2.5rem;
            flex: 7;
            &-start,
            &-end,
            &-hour {
                flex: 2;
            }
            .title {
                font-size: 1.2rem;
                color: #d4d4d4;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            }
        }
        .desktop {
            display: none;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: flex;
                width: 60%;
                padding-top: 0;
            }
        }
        .mobile {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: none;
            }
        }
    }
`;
export const StyledDetailView = styled.div`
    padding-bottom: 4rem;
    .detail {
        min-height: 100vh;
        background-image: url(${small_bg_dot});
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        text-align: left;
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        &__top {
            display: flex;
            flex-direction: column;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                flex-direction: row;
            }
            &-status {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding-top: 1rem;
                    padding-left: 2rem;
                }
            }
            &-title {
                font-size: 3.2rem;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            }
            &-icon {
                width: 5rem;
                margin-right: 4rem;
                div {
                    width: 100%;
                    button {
                        width: 100%;
                        display: flex;
                        justify-content: end;
                    }
                }
                .MuiIconButton-root {
                    padding-left: 0;
                }
            }
            &-checked {
                padding: 0.4rem 1rem;
                background-color: #d6fddc;
                display: flex;
                width: 6rem;
                .text {
                    padding-right: 0.5rem;
                    color: #0aba3b;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-unChecked {
                padding: 0.4rem 1rem;
                background-color: #fdd6d6;
                display: flex;
                width: 6rem;
                .text {
                    padding-right: 0.5rem;
                    color: #ba0a0a;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-pending {
                padding: 0.4rem 1rem;
                background-color: #e9e9e9;
                width: 6rem;
            }
        }
        .wrapper {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        &__content {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 45rem;
            }
            &-info,
            &-time {
                margin: 3rem 0;
                padding: 1.5rem 1.6rem;
                background-color: ${(p) => p.theme.colors.white};
                border-radius: 1.6rem;
            }
            .user__timmer {
                display: flex;
                justify-content: space-between;
            }
            .user {
                display: flex;
                padding: 1.5rem 0;
                &__name {
                    width: 70%;
                }
                &__avatar {
                    display: flex;
                    padding-top: 1rem;
                }
                &__profile {
                    padding-right: 1rem;
                }
                &__text-main {
                    display: flex;
                    align-items: center;
                    font-size: 1.6rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                    padding-top: 1rem;
                }
                &__timesheet {
                    /* margin-bottom: 1rem; */
                    white-space: nowrap;
                }
                &-right {
                    margin-top: 2rem;
                    &timmer {
                    }
                }
                &-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-start;
                    &__left {
                        width: 70%;
                    }
                }
            }
            .date {
                display: flex;
                justify-content: space-start;
                margin-bottom: 1rem;
                .content {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                    font-size: 1.6rem;
                }
                &__left {
                    width: 70%;
                }
                &__right {
                    /* margin-left: 9rem; */
                }
            }
            .des {
                padding-top: 1rem;
                &__title {
                    padding: 1rem 0;
                }
                &__content {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                    font-size: 1.6rem;
                }
            }
            &-top__title {
                margin-top: 1.25rem;
                text-align: center;
                font-size: 2.25rem;
                line-height: 2.25rem;
                font-weight: 600;
                span {
                    margin: 0 1rem;
                    @media (max-width: 375px) {
                        font-size: 16px;
                    }
                }
                span:first-child {
                    margin-left: 0;
                }
                span:last-child {
                    margin-right: 0;
                }
            }
        }
    }
`;
export const StyledNewOT = styled.div`
    padding-bottom: 4rem;
    .newOT {
        min-height: 100vh;
        background-image: url(${small_bg_dot});
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        text-align: left;
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        .MuiFormGroup-root {
            display: flex;
            flex-direction: row;
        }
        .MuiFormControlLabel-root {
            margin-right: 16px !important;
            margin-left: 0 !important;
            height: 4.5rem;
        }
        .datePicker {
            .MuiOutlinedInput-input {
                padding: 8.5px 14px !important;
                height: 1.4375em;
            }
        }
        &__top {
            display: flex;
            flex-direction: column;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                flex-direction: row;
            }
            &-status {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding-top: 1rem;
                    padding-left: 2rem;
                }
            }
            &-title {
                font-size: 3.2rem;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            }
            &-icon {
                width: 5rem;
                margin-right: 4rem;
                div {
                    width: 100%;
                    button {
                        width: 100%;
                        display: flex;
                        justify-content: end;
                    }
                }
                .MuiIconButton-root {
                    padding-left: 0;
                }
            }
        }
        .wrapperContent {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .content {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 80rem;
            }
        }
        .approval {
            margin-top: 2rem;
            padding: 2.5rem 1.5rem;
            border-radius: 1.6rem;
            background-color: white;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 5rem 8rem;
            }
        }
        &__user {
            margin-top: 2rem;
            padding: 2.5rem 1.5rem;
            border-radius: 1.6rem;
            background-color: white;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 5rem 8rem;
            }
            .id {
                font-size: 1.6rem;
                color: #000000;
            }
            .userInfo {
                display: flex;
                margin-bottom: 1rem;
                width: 100%;
                justify-content: space-between;
                &__name {
                    /* width: 70%; */
                    min-width: 32 rem;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                        min-width: 32 rem;
                    }
                }
                &__typeTempo {
                    font-size: 2rem;
                    font-weight: 900;
                    color: black;
                    height: fit-content;
                    padding: 10px;
                    border: 2px solid orange;
                    border-radius: 1.6rem;
                    :hover {
                        background-color: orange;
                        color: black;
                        cursor: pointer;
                    }
                }
                &__typeOfficial {
                    font-size: 2.5rem;
                    font-weight: 900;
                    color: black;
                }
                .title {
                    color: #656565;
                }
                .wrapperAvatar {
                    display: flex;
                    padding-top: 1rem;
                }
                .id {
                    padding-top: 2rem;
                }
                .name {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                    font-size: 1.6rem;
                    display: flex;
                    align-items: center;
                    padding-top: 1rem;
                    padding-left: 1rem;
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    /* height: 40px; */
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .label-type-overtime {
                label {
                    padding-right: 1rem;
                    padding-left: 1rem;
                    border-radius: 1.6rem;
                    display: flex;
                    justify-content: center;
                    &.active {
                        background-color: white;
                    }
                    background-color: orange;
                }
            }
            .user__title,
            .user__department {
                padding: 1.2rem 0;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    margin-top: 2rem;
                }
            }
        }
        .label {
            padding-bottom: 0.3rem;
        }
        .input {
            padding-top: 1rem;
        }
        .dateTime {
            display: flex !important;
            .MuiOutlinedInput-input {
                padding: 8.5px 14px !important;
                height: 1.4375em;
            }
            &__date {
            }
            &__time {
                margin-left: 20px;
            }
        }
        &__time {
            margin-top: 2rem;
            padding: 2.5rem 1.5rem;
            border-radius: 1.6rem;
            background-color: white;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 5rem 8rem;
            }
        }
        &__btn {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            .wrapperBtn {
                width: 100%;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    width: 40rem;
                }
            }
            .delete {
                /* margin: 2rem 0 !important; */
                width: 100%;
                .MuiButton-root {
                    background-color: ${(p) => p.theme.colors.secondary};
                }
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    width: 40rem;
                }
            }
        }
        .note {
            padding-bottom: 1rem;
            &__subTitle {
                padding-left: 0.5rem;
                color: #656565;
            }
        }
    }
`;
export const StyledOTApprovalProcess = styled.div`
    padding: 2rem 0;
    .process {
        margin-bottom: 1rem;
        &__divider {
            min-height: 5rem;
            width: 1px;
            background-color: #dedede;
            margin-left: 0.5rem;
        }
        &__top,
        &__bottom {
            display: flex;
        }
        &__bottom {
            padding: 0;
        }
        &__circle {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 100%;
            background-color: blue;
            margin-right: 2rem;
        }
        &__title {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            font-size: 1.4rem;
            color: #8e8e8e;
        }
        &__content {
            width: 80%;
        }
        .note {
            color: #656565;
        }
        &__person {
            display: flex;
            padding-top: 0.5rem;
            .MuiAvatar-root {
                width: 3rem;
                height: 3rem;
            }
            &-name {
                display: flex;
                align-items: center;
                padding-left: 1rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            }
        }
        &__date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-time {
                display: flex;
                text-align: center;
            }
        }
        &__status {
            &-checked {
                padding: 0.4rem 1rem;
                background-color: #d6fddc;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #0aba3b;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-unChecked {
                padding: 0.4rem 1rem;
                background-color: #fdd6d6;
                display: flex;
                .text {
                    padding-right: 0.5rem;
                    color: #ba0a0a;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-pending {
                padding: 0.4rem 1rem;
                background-color: #e9e9e9;
            }
        }
    }
    .processForm {
        margin: 2rem;
        .MuiRadio-root {
            .MuiSvgIcon-root {
                width: 1.5em;
                height: 1.5em;
            }
        }
        .title {
            font-size: 1.4rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            padding-right: 0.5rem;
        }
        .Mui-checked {
            color: ${(p) => p.theme.colors.primary};
        }
    }
`;
export const StyledOverTimeCalendar = styled.div`
    // min-height: 80rem;
    /* .rbc-calendar {
        height: 80rem;
    } */
    .rbc-event,
    .rbc-day-slot .rbc-background-event {
        background-color: ${(p) => p.theme.colors.white}!important;
    }
    .rbc-month-row {
        overflow: visible !important;
    }
    .rbc-toolbar {
        display: none;
    }
    .rbc-event {
        cursor: pointer;
        position: relative;
        height: 100%;
        min-height: 20px;
        max-height: 180px;
        overflow-y: auto;
        z-index: 10;
    }
    .rbc-event:hover {
        scrollbar-width: 3px;
        overflow-y: auto;
    }
    .rbc-event::-webkit-scrollbar {
        width: 4px;
    }

    .rbc-event::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    .rbc-event::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 4px;
    }

    .rbc-event::-webkit-scrollbar-thumb:hover {
        background-color: #888;
    }
    .rbc-event:focus {
        outline: none;
    }
    .rbc-row-content {
        position: relative;
        user-select: auto;
        /* -webkit-user-select: none; */
        z-index: 10;
    }
    .rbc-selected-cell {
        background: transparent;
    }
    .rbc-today {
        background-color: transparent;
    }
`;

export const StyledOverTimeEventContent = styled.div`
    .groupUser {
        .mine {
            .content {
                background-color: #b0eefc !important;
                padding: 0.4rem !important ;
            }
            border-radius: 1rem !important;
        }
        .user {
            display: flex;
            flex-direction: column;
            color: ${(p) => p.theme.colors.darkBlack};
            .more {
                &__dot {
                    padding: 0.2rem 0.8rem;
                    text-align: center;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                        display: none;
                    }
                }
            }
            .wrapperAvatar {
                padding: 0.5rem;
                display: flex;
                z-index: 1000;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding: 0.5rem 1.5rem;
                }
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding: 0.5rem;
                    border-radius: 1rem;
                    padding: 0.5rem 0.8rem;
                }
            }
            .content {
                display: flex;
                background-color: #e7e7e7;
                padding: 0;
                border-radius: 1.6rem;
                align-items: center;

                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding: 0.5rem 1rem;
                }
            }
            .name {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                font-size: 1.4rem;
                text-align: center;
                display: none;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    display: block;
                    display: -webkit-box;
                    max-width: 9rem;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 1rem;
                }
            }
            .MuiAvatar-root {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
    .mobile {
        &__dot {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: none;
            }
        }
    }
    .more__text {
        display: none;
        font-size: 1.3rem;
        padding-left: 2rem;
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        color: #656565;
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            display: block;
        }
    }
`;

export const StyledWrapCalendarComponent = styled.div`
    margin-top: 15px;
    .rbc-month-view {
        z-index: 0;
    }
    .cvsExportDiv {
        display: inline-flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 1rem;
        padding: 2rem;
        gap: 2rem;
        margin-top:1rem;
    }
    .csvExportTitle {
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
        font-size: 1.6rem;
    }
    .ot {
        padding: 1rem 1.5rem;
        min-height: 80vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        &__datePicker {
            display: flex;
            align-items: center;
            gap: 1rem;
            .label {
                display: flex;
                align-items: center;
            }
            .wrapperDatePicker {
                width: 18rem;
            }
            .inputDate {
                padding: 1rem;
                border-radius: 1rem;
                border: 1px solid #888;
                font-size: 1.5rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            }
        }
        &__calendar {
            padding-top: 2rem;
        }
    }
    .inputMonth {
        padding: 1rem;
        border-radius: 1rem;
        border: 1px solid #888;
        font-size: 1.5rem;
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
    }
    .dateContainer {
        @media (min-width: 768px) {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
        @media (min-width: 100px) and (max-width: 768px) {
        }
`;

export const StyledOverTimeContentModal = styled.div`
    padding: 2.5rem 4rem;

    .toggle {
        &__top {
            &-collapse {
                display: flex;
            }
            .collapse-title {
                font-size: 1.4rem;
            }
            padding-bottom: 2.4rem;
            &-date {
                font-size: 3.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            }
            &-icon {
                width: 2.5rem;
                height: 2.5rem;
                cursor: pointer;
            }
        }
        &__user {
            margin-top: 3rem;
            margin-bottom: 3rem;
            cursor: pointer;
            &-item {
                display: flex;
                padding: 0.6rem 1.2rem;
                background-color: #b0eefc;
                border-radius: 1rem;
                margin-bottom: 2rem;
            }
            &-name {
                padding-top: 1rem;
                padding-left: 1rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.4rem;
                display: flex;
                align-items: center;
                height: 3.5rem;
                display: -webkit-box;
                max-width: 100%;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .wrapperList {
            max-height: 70vh;
            overflow-y: auto;
        }
        &__groupUser {
            padding-top: 2rem;
            padding-bottom: 2rem;
            &-title {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.6rem;
            }
            &-list {
                padding-left: 2rem;

                .item {
                    padding: 1rem 0;
                    display: flex;
                    cursor: pointer;
                    &__title {
                        margin-top: 1rem;
                        padding-left: 1rem;
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                        font-size: 1.4rem;
                        display: flex;
                        align-items: center;
                        height: 3.5rem;
                        display: -webkit-box;
                        max-width: 100%;
                        line-height: 1.2;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
`;
export const StyledOverTimePopoverList = styled.div`
    display: flex;
    flex-direction: column;

    .contain {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .wrapItem {
            padding: 2rem;
            cursor: pointer;
            transition: 250ms;
            display: flex;
            justify-content: flex-end;
            &:hover {
                background-color: #43cdec;
            }
        }

        .wrapItemTempo {
            padding: 2rem 0rem;
            cursor: pointer;
            transition: 250ms;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .item {
            font-size: 1.4rem;
            padding: 0 !important;
            &__status {
                &-checked {
                    padding: 0.4rem 1rem;
                    background-color: #d6fddc;
                    display: flex;
                    .text {
                        padding-right: 0.5rem;
                        color: #0aba3b;
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    }
                    .icon {
                        display: block;
                        padding-top: 0.1rem;
                    }
                }
                &-unChecked {
                    padding: 0.4rem 1rem;
                    background-color: #fdd6d6;
                    display: flex;
                    .text {
                        padding-right: 0.5rem;
                        color: #ba0a0a;
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    }
                    .icon {
                        display: block;
                        padding-top: 0.1rem;
                    }
                }
                &-pending {
                    padding: 0.4rem 1rem;
                    background-color: #e9e9e9;
                }
                &-official {
                    padding: 0.4rem 1rem;
                    background-color: red;
                    color: #fff;
                    &:hover {
                        background-color: #fdd7d7;
                    }
                }
            }
        }
    }
    .title {
        font-size: 1.4rem;
        padding-right: 2rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: flex-end;
    }
`;

export const TextTile = styled.p`
    font-size: 16px;
    font-weight: 700;
`;
