import React from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

import { AppTopBar, TitleMenuComponent, AppInput, AppIconButton } from "..";
import { appRouteEnums, menuTitleEnums } from "@enums";
import { SearchIcon } from "@assets";
import { selectPreloadData, selectPreloadDataLoading, setInstrumentsCategory, useAppSelector } from "@redux";
import {
    StyledInstrumentsView,
    InstrumentsListItemComponent,
    InstrumentCatetoryComponent,
} from ".";
//import { categoriesApi, instrumentApi } from "@api";
//import { updateSnackBar } from "@redux";
import { API_CATEGORY_TYPE_INSTRUMENT, PLACEHOLDER_SEARCH } from "@configs";
import { useWindowDimensions } from "@utils";
import { useHistory, useLocation } from "react-router-dom";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
//import { sub } from "date-fns";
//import { update } from "lodash";

export const InstrumentsView = () => {
    // hooks
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");
    //const subCategoryId = query.get("subCategoryId");
    const dispatch = useDispatch();
    const width = useWindowDimensions().width;
    const history = useHistory();
    //component state
    const [inputValue, setInputValue] = React.useState<string>("");
    //const [categories, setCategories] = React.useState<Array<any>>();
    const [category, setCategory] = React.useState<string>("");
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const messagesEndRef = React.useRef<any>(null);
    //const [loading, setLoading] = React.useState<boolean>(false);
    //const [listProducts, setListProducts] = React.useState<any>();
    const [categoryViewMobile, setCategoryViewMobile] = React.useState<boolean>(true);

    const preloadData = useAppSelector(selectPreloadData);
    const preloadDataLoading = useAppSelector(selectPreloadDataLoading);

    const instruments = !preloadData?.instrumentItems ? [] : preloadData.instrumentItems
        .filter(instrument => instrument.status)
        .map(instrument => {
        const instrumentCopy = { ...instrument };
        const instrumentObject = preloadData.instruments.find(item => item.id === instrumentCopy.instrument_id);
        const instrumentAttachment = preloadData.attachments.find(attachment =>
            attachment.attachmentable_id === instrumentCopy.id &&
            attachment.attachmentable_type.toLowerCase().endsWith("instrumentitem")
        );

        const instrumentImage = {
            id: instrumentAttachment?.id,
            path: instrumentAttachment?.path,
            updated_at: instrumentAttachment?.updated_at,
        };

        const instrumentBorrowings = preloadData.borrowings.filter(borrowing => 
            borrowing.borrowingable_id === instrumentCopy.id &&
            borrowing.borrowingable_type.toLowerCase().endsWith("instrumentitem")
        );
        const instrumentReturns = preloadData.returnedItems.filter(returnedItem =>
            returnedItem.borrowingable_id === instrumentCopy.id &&
            returnedItem.borrowingable_type.toLowerCase().endsWith("instrumentitem")
        );

        let borrowing = false;
        let isLate = false;

        instrumentBorrowings.forEach(borrowingItem => {
            const connectedReturn = instrumentReturns.find(returnItem => returnItem.borrowing_id === borrowingItem.id);
            if (!connectedReturn) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const startDate = new Date(borrowingItem.from);
                const endDate = new Date(borrowingItem.to);
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(0, 0, 0, 0);

              if(startDate <= today && today <= endDate){
                borrowing = true;
              }

              if (endDate < today) {
                borrowing = true;
                isLate = true;
              }
            }
        });

        return {
            ...instrumentCopy,
            name: instrumentObject?.name,
            image: instrumentImage,
            borrowing: !borrowing, // This is BACKWARDS, the child component uses borrowing=true to mean it's NOT being borrowed
            isLate: isLate,
        };
    }).sort((a, b) => a.position - b.position);

    //console.log(instruments);

    const instrumentCategories = !preloadData?.categories ? [] : preloadData.categories
        .filter(category => category.type === API_CATEGORY_TYPE_INSTRUMENT && category.parent_id === 0) // Get top-level instrumen categories
        .sort((a, b) => a.position - b.position)
        .map(parentCategory => ({
            ...parentCategory, // Copy all properties of the parent category
            title: parentCategory.name, // Rename 'name' to 'title'
            subCategory: preloadData.categories
                .filter(subCategory => subCategory.type === API_CATEGORY_TYPE_INSTRUMENT && subCategory.parent_id === parentCategory.id) // Get matching instrumen sub-categories
                .sort((a, b) => a.position - b.position)
    }));

    //console.log(instrumentCategories);

    // call api get categories
//    React.useEffect(() => {
//        const fetchCategoriesApi = async () => {
//            try {
//                const response = await categoriesApi.getCategories({
//                    type: API_CATEGORY_TYPE_INSTRUMENT,
//                });
//                setCategories(response.data.data);
//            } catch (err: any) {
//                dispatch(
//                    updateSnackBar({
//                        messageType: "error",
//                        message: err.response?.data?.message,
//                    })
//                );
//            }
//        };
//        fetchCategoriesApi();
//    }, []);
    // events
    const handleOnChange = (value: string) => {
        setInputValue(value);
    };
    //What: scroll to bottom to view data results when device is mobile
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    //what: call api get list products
//    const fetchListProducts = async (params: any) => {
//        setLoading(true);
//        try {
//            const response = await instrumentApi.getViewListProducts({
//                category: subCategoryId?.toString() || "",
//                name: params.name || "",
//            });
//            setLoading(false);
//            setListProducts(response.data.data);
//        } catch (err: any) {
//            setListProducts(undefined);
//            setLoading(false);
//        }
//    };
//    React.useEffect(() => {
//        const fetchCategoriesApi = async () => {
//            try {
//                const response = await categoriesApi.getCategories({
//                    type: API_CATEGORY_TYPE_INSTRUMENT,
//                });
//                setCategories(response.data.data);
//            } catch (err: any) {
//                dispatch(
//                    updateSnackBar({
//                        messageType: "error",
//                        message: err.response.data.message,
//                    })
//                );
//            }
//        };
//        fetchCategoriesApi();
//    }, []);

    //what: call api in search form
    const handleOnSearchSubmit = () => {
        setCategoryViewMobile(false);
//        fetchListProducts({
//            name: inputValue,
//            category: "",
//        });
        setSelectedIndex(-1);
        if (width < 768) {
            scrollToBottom();
        }
        dispatch(
            setInstrumentsCategory({
                category: "",
                subCategory: "",
            })
        );
    };

    //what: select a subCategory ID
    const handleSelectCategory = (values: any, categoryId: number) => {
        dispatch(
            setInstrumentsCategory({
                category: values.title,
                subCategory: values.subTitle,
            })
        );
        setInputValue("");
        setCategoryViewMobile(false);
        setCategory(values.id);
        history.push(`/instruments?categoryId=${categoryId}&subCategoryId=${values.id}`);
    };
    //what: call api after re-render
    //how: when category id is updated, this page is re-render
//    React.useEffect(() => {
//        fetchListProducts({
//            category: category,
//        });
//    }, [category]);
    //WHAT: active select category
    const handleClick = (index: any) => {
        setSelectedIndex(index);
    };

    const filteredInstruments = category ? 
        instruments.filter(instrument => instrument.category_id === category) :
        inputValue ? instruments.filter(instrument => instrument.name.toLowerCase().includes(inputValue.toLowerCase())) :
        instruments;
    
    const inputValueMatchCount = inputValue ? filteredInstruments.length : undefined;

    return (
        <StyledInstrumentsView>
            <AppTopBar />
            <div className="instruments">
                <div className="instruments__top">
                    <p
                        className="newOT__top-icon"
                        onClick={() => history.push(appRouteEnums.PATH_HOME)}
                    >
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </p>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={8}>
                            <div className="instruments__top-title">
                                <TitleMenuComponent text={menuTitleEnums.TITLE_INSTRUMENTS} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div className="instruments__top-searchForm" style={{flexDirection: "column"}}>
                                <AppInput
                                    value={inputValue}
                                    iconStart={<SearchIcon />}
                                    handleChange={handleOnChange}
                                    placeholder={PLACEHOLDER_SEARCH}
                                    disabled={false}
                                    name="searchValue"
                                    onClick={handleOnSearchSubmit}
                                />
                                {inputValueMatchCount !== undefined ? <span style={{fontSize: "0.8em", color:"#666", padding:"0.5rem 2rem"}}>一致数: {inputValueMatchCount}</span> : null}
                                </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="instruments__content visible-pc ">
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <div className="category">
                                {instrumentCategories &&
                                    instrumentCategories?.map((category, index) => (
                                        <div key={category.id}>
                                            <InstrumentCatetoryComponent
                                                category={category}
                                                checked={
                                                    String(categoryId) === String(category.id) ||
                                                    index === selectedIndex
                                                }
                                                onClick={() => {
                                                    handleClick(index);
                                                }}
                                                handleSelectSubCategory={handleSelectCategory}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <div className="listItem" ref={messagesEndRef}>
                                <InstrumentsListItemComponent
                                    loading={preloadDataLoading}
                                    products={filteredInstruments}
                                    categoryId={category}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="instruments__content visible-mobile">
                    {categoryViewMobile ? (
                        <div className="category">
                            {instrumentCategories &&
                                instrumentCategories?.map((category, index) => (
                                    <div key={category.id}>
                                        <InstrumentCatetoryComponent
                                            category={category}
                                            checked={
                                                String(categoryId) === String(category.id) ||
                                                index === selectedIndex
                                            }
                                            onClick={() => {
                                                handleClick(index);
                                            }}
                                            handleSelectSubCategory={handleSelectCategory}
                                        />
                                    </div>
                                ))}
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{ width: "100%", textAlign: "left", padding: "2rem 0" }}
                                onClick={() => setCategoryViewMobile(true)}
                            >
                                <img src={mobile_arrow_left} alt="icon" />
                            </div>
                            <InstrumentsListItemComponent
                                loading={preloadDataLoading}
                                products={filteredInstruments}
                                categoryId={category}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledInstrumentsView>
    );
};
