//import { selectAuth, useAppSelector } from "@redux";
import { getDaysWeeksArrayMoment } from "@utils";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { japaneseHolidays } from "src/Utils/holidays";
import styled from "styled-components";
import WeeklyScheduleRow from "./WeeklyScheduleRow";
import { weeklyScheduleApi } from "src/Api/weekly-schedule";

type WeeklyScheduleCalendarProps = {
    data?: any;
    month?: string;
};

export const WeeklyScheduleCalendar = (props: WeeklyScheduleCalendarProps) => {
    const today = moment().format('YYYY-MM');
    const { data, month = today } = props;
    //const auth = useAppSelector(selectAuth);
    //const { userInfo } = auth;
    const holidays = japaneseHolidays;

    const datesInMonth = getDaysWeeksArrayMoment(month);

    const initialEditing = (!data || data.length === 0) ? {} :
        Object.fromEntries(
            data.map((user: any) => [
                user.id,
                Object.fromEntries(
                    datesInMonth.map((date: any) => [
                        date.dayNumber,
                        false
                    ])
                )
            ])
        );

    const initialScheduleData = generateScheduleObject(data);

    const storedUserOrder = localStorage.getItem('userOrder') ? JSON.parse(localStorage?.getItem('userOrder') || "[]") : [];
    const newUsers = data.map((user) => user.id).filter((userId) => !storedUserOrder.includes(userId));
    const initialUserOrder = [...storedUserOrder, ...newUsers];

    const calendarRef = useRef<HTMLDivElement | null>(null);
    const namesHeaderRef = useRef<HTMLDivElement | null>(null);
    const [userOrder, setUserOrder] = useState<number[]>(initialUserOrder);
    const [editing, setEditing] = useState(initialEditing); // array of users x days
    const [scheduleData, setScheduleData] = useState(initialScheduleData);

    useEffect(() => {
        if (!calendarRef.current || !namesHeaderRef.current || !datesInMonth) return;
    
        const today = new Date();
        const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
        
        // Find the column for today
        const todayElement = calendarRef.current.querySelector(`[data-date="${todayString}"]`);
        if (todayElement) {
            const calendarContainer = calendarRef.current;
            const namesHeader = namesHeaderRef.current;
    
            // Calculate the scroll offset
            const containerRect = calendarContainer.getBoundingClientRect();
            const namesHeaderRect = namesHeader.getBoundingClientRect();
            const todayRect = todayElement.getBoundingClientRect();
    
            const scrollOffset = todayRect.left - containerRect.left - namesHeaderRect.width;
    
            // Scroll to today's column
            calendarContainer.scrollLeft += scrollOffset;
        }
    }, [calendarRef, namesHeaderRef, datesInMonth]);

    const saveOrderToLocalStorage = (newOrder: number[]) => {
        localStorage.setItem('userOrder', JSON.stringify(newOrder));
    };

    const handleDrop = (draggedUserId: number, targetUserId: number) => {
        const newOrder = [...userOrder];
        const draggedIndex = newOrder.indexOf(draggedUserId);
        const targetIndex = newOrder.indexOf(targetUserId);
    
        // Remove dragged user and insert it before the target
        newOrder.splice(draggedIndex, 1);
        newOrder.splice(targetIndex, 0, draggedUserId);
    
        setUserOrder(newOrder);
        saveOrderToLocalStorage(newOrder);
    };

    const handleEditClick = (userId: number, dayNumber: number, newValue: boolean) => {
        setEditing((prevEditing) => {
            const newEditing = { ...prevEditing, [userId]: { ...prevEditing[userId] } }; // Shallow copy for userId entry
            newEditing[userId][dayNumber] = newValue;
            return newEditing;
        });
    };

    const handleChangeScheduleInput = (userId: number, dayNumber: number, contentOne: string | null, contentTwo: string | null) => {
        setScheduleData((prevSchedule) => {
            const prevContent = prevSchedule[userId][dayNumber];
            const newContent = { contentOne: contentOne ?? prevContent.contentOne, contentTwo: contentTwo ?? prevContent.contentTwo };
            const newSchedule = { ...prevSchedule, [userId]: { ...prevSchedule[userId], [dayNumber]: newContent } };
            return newSchedule;
        });
    };

    const handleUndoClick = (userId: number, dayNumber: number, originalContentOne: string, originalContentTwo: string) => {
        setScheduleData((prevSchedule) => {
            const newSchedule = { ...prevSchedule, [userId]: { ...prevSchedule[userId], [dayNumber]: { contentOne: originalContentOne, contentTwo: originalContentTwo } } };
            return newSchedule;
        });
    };

    const handleSaveClick = async (userId: number, dayNumber: number, contentOne: string, contentTwo: string) => {
        const currentDate = `${month}-${String(dayNumber).padStart(2, '0')}`;
        const body = {
            data: [
                {
                    user_id: userId,
                    date: currentDate,
                    content_one: contentOne,
                    content_two: contentTwo
                }
            ]
        };
        try {
            await weeklyScheduleApi.createNoteWeeklySchedule(body);
        } catch (error) {
            //console.error(error);
        }
    };

    function generateScheduleObject(data: any) {
        return (!data || data.length === 0) ? {} : 
        Object.fromEntries(
            data.map((user: any) => [
                user.id,
                Object.fromEntries(
                    datesInMonth.map((date: any) => {
                        const schedule = user.schedule.find((s: any) => new Date(s.date).getDate() === parseInt(date.dayNumber));
                        const contentOne = schedule?.content_one ?? "";
                        const contentTwo = schedule?.content_two ?? "";
    
                        return [
                            date.dayNumber,
                            {
                                contentOne: contentOne,
                                contentTwo: contentTwo
                            }
                        ];
                    })
                )
            ])
        );
    };

    useEffect(() => {
        setScheduleData(generateScheduleObject(data));
    }, [data]);

    const headerRow = (!month) ? null : (
        <StyledHeaderContainer>
            <StyledRow>
                <StyledStickyUserElement ref={namesHeaderRef} style={{margin: 0, width: "12rem"}}>リスト名</StyledStickyUserElement>
                {datesInMonth.map((date, index) => {
                    const currentDate = `${month}-${String(date.dayNumber).padStart(2, '0')}`;
                    const holiday = holidays.find(h => h.date === currentDate);
                    const dayColor = date.dayInWeek === "sat" ? "#008" : date.dayInWeek === "sun" ? "#800" : holiday ? "#f00" : "#000";
                    return (
                        <StyledHeaderElement key={index} style={{color: dayColor}} data-date={currentDate}>{date.dayNumber}</StyledHeaderElement>
                    );
                })}
            </StyledRow>
        </StyledHeaderContainer>
    );

    const userRows =  userOrder.map((usrId) => {
        const user = data.find((usr: any) => usr.id === usrId);
        if(!user) return null;
        const userScheduleData = scheduleData?.[user.id];
        return (
            <WeeklyScheduleRow
                key={user.id}
                user={user}
                datesInMonth={datesInMonth}
                onEditClick={handleEditClick}
                onChangeScheduleInput={handleChangeScheduleInput}
                onUndoClick={handleUndoClick}
                onSaveClick={handleSaveClick}
                onDrop={handleDrop}
                editing={editing}
                scheduleData={userScheduleData}
                month={month}
                holidays={holidays}
            />
        );
    });


    return (
        <StyledCalendar ref={calendarRef}>
            {headerRow}
            {userRows}
        </StyledCalendar>
    );
};

const StyledCalendar = styled.div`
    overflow-x: auto;
    max-height: 80vh;
`;

const StyledHeaderContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: 10; /* Ensure it stays on top of the rows */
    max-width: 100%;
`;

const StyledRow = styled.div`
    display: inline-flex;
    border-left: 2px solid #fff;
    box-shadow: rgba(33, 35, 38, 0.25) 0px 10px 10px -10px;
`;

const StyledHeaderElement = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 4rem;
    background-color: rgb(235, 230, 230);
    margin: 0 0.1rem;
`;

const StyledStickyUserElement = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 4rem;
    background-color: rgb(235, 230, 230);
    margin: 0 0.1rem;
    position: sticky;
    left: 0;
    z-index: 5;
`;